import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Box,
  useTheme,

} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import GlobalContext from '../context/GlobalContext'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.black,
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.2s ease',
    '&$loaded': { opacity: 0 },
  },
  loaded: {},
}))

export default function Loader() {
  const classes = useStyles()
  const theme = useTheme()
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setMounted(true) // only show if has been loading for atleast 350
    }, 350)
  }, [])
  return (
    <GlobalContext.Consumer>
      {({ loaded, isLoaded }) => !loaded && !mounted
        && (
        <Box style={{ color: theme.palette.primary.main }} className={`${classes.root} ${isLoaded ? classes.loaded : ''}`}>
          <CircularProgress color="inherit" />
        </Box>
        )}
    </GlobalContext.Consumer>
  )
}
