const isExternal = (url) => !(url.includes(window.location.hostname) || url.charAt(0) === '/')

const trackLinkClick = (evt) => {
  let { target } = evt

  if (evt.target.tagName !== 'A') {
    target = target.closest('a')
  }
  const anchorElement = target

  window.dataLayer.push({
    event: 'click',
    link_url: anchorElement.href,
    link_id: anchorElement.id,
    link_classes: anchorElement.className,
    link_text: anchorElement.innerText,
    outbound: isExternal(anchorElement.href),
  })
}

export default trackLinkClick
