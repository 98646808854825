import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles({
  logo: {
    height: '100%',
    width: '100%',
    maxWidth: '180px',
    maxHeight: '54px',
  },
})

const LogoAlt = () => {
  const {
    logo,
  } = useStyles()

  return (
    <>
      <Hidden xsUp><p>Rise | Empowered by TriNet</p></Hidden>
      <SvgIcon className={logo} width="177" height="53" viewBox="0 0 177 53" fill="none" xmlns="http://www.w3.org/2000/svg">

        <path d="M51.4601 6.85521L6.85448 51.4608C5.28156 53.0337 2.75895 53.0337 1.18604 51.4608C-0.386882 49.8879 -0.386882 47.3653 1.18604 45.7924L45.7916 1.18677C47.3645 -0.386149 49.8871 -0.386149 51.4601 1.18677C53.0033 2.73001 53.0033 5.28229 51.4601 6.85521Z" fill="white" />
        <path d="M15.9962 42.2905L6.85545 51.4312C5.28254 53.0042 2.75993 53.0042 1.18701 51.4312C-0.385905 49.8583 -0.385905 47.3357 1.18701 45.7628L10.3277 36.6221C11.9007 35.0492 14.4233 35.0492 15.9962 36.6221C17.5691 38.195 17.5691 40.7473 15.9962 42.2905Z" fill="#307EDA" />
        <path d="M33.2685 6.97459L6.97411 33.269C5.40119 34.842 2.87858 34.842 1.30567 33.269C-0.267253 31.6961 -0.267253 29.1735 1.30567 27.6006L27.6001 1.30615C29.173 -0.266765 31.6956 -0.266765 33.2685 1.30615C34.8415 2.87907 34.8415 5.40168 33.2685 6.97459Z" fill="white" />
        <path d="M51.4017 25.0771L25.1073 51.3716C23.5344 52.9445 21.0118 52.9445 19.4388 51.3716C17.8659 49.7987 17.8659 47.2761 19.4388 45.7031L45.7036 19.4087C47.2765 17.8358 49.7991 17.8358 51.372 19.4087C52.945 20.9816 52.945 23.5339 51.4017 25.0771Z" fill="white" />
        <path d="M30.4491 8.13188C32.6618 8.13188 34.4556 6.33811 34.4556 4.12539C34.4556 1.91266 32.6618 0.118896 30.4491 0.118896C28.2364 0.118896 26.4426 1.91266 26.4426 4.12539C26.4426 6.33811 28.2364 8.13188 30.4491 8.13188Z" fill="#40A36D" />
        <path d="M48.5528 26.2637C50.7655 26.2637 52.5592 24.4699 52.5592 22.2572C52.5592 20.0445 50.7655 18.2507 48.5528 18.2507C46.34 18.2507 44.5463 20.0445 44.5463 22.2572C44.5463 24.4699 46.34 26.2637 48.5528 26.2637Z" fill="#307EDA" />
        <path d="M48.6121 8.01298C50.8248 8.01298 52.6186 6.21921 52.6186 4.00649C52.6186 1.79377 50.8248 0 48.6121 0C46.3994 0 44.6056 1.79377 44.6056 4.00649C44.6056 6.21921 46.3994 8.01298 48.6121 8.01298Z" fill="#FF5B16" />
        <path d="M13.1769 43.4773C15.3896 43.4773 17.1834 41.6836 17.1834 39.4708C17.1834 37.2581 15.3896 35.4644 13.1769 35.4644C10.9642 35.4644 9.17041 37.2581 9.17041 39.4708C9.17041 41.6836 10.9642 43.4773 13.1769 43.4773Z" fill="#6630DA" />
        <path d="M82.6225 31.6942H77.0728V43.7434H68.7927V8.87207H84.3438C91.8226 8.87207 96.8381 13.4424 96.8381 20.2683C96.8381 24.6606 94.5826 28.0735 90.8433 30.0323L99.6872 43.7434H89.9826L82.6225 31.6942ZM77.0728 24.8387H82.6819C86.0355 24.8387 88.291 22.9987 88.291 20.2683C88.291 17.538 86.3322 15.7276 83.4238 15.7276H77.0728V24.8387Z" fill="white" />
        <path d="M104.02 8.87207H112.3V43.7434H104.02V8.87207Z" fill="white" />
        <path d="M118.74 39.6775L121.856 34.1278C124.972 36.5317 128.207 37.3923 131.234 37.3923C135.003 37.3923 137.022 36.3536 137.022 34.2762C137.022 32.0207 134.469 31.071 130.611 30.0323L128.831 29.5574C122.658 27.8955 118.265 25.3729 118.265 19.5264C118.265 12.8786 123.637 8.39722 131.739 8.39722C136.013 8.39722 140.256 9.614 143.936 12.4037L140.316 18.1612C137.674 16.1728 135.003 15.2528 131.828 15.2528C128.771 15.2528 126.783 16.3805 126.783 18.1612C126.783 20.3277 129.276 21.1883 132.54 22.0193L134.321 22.5238C139.93 24.0374 145.539 27.1535 145.539 33.4452C145.539 39.8853 139.989 44.1885 131.68 44.1885C126.723 44.2182 122.153 42.6156 118.74 39.6775Z" fill="white" />
        <path d="M176.463 36.9175V43.7434H151.267V8.87207H176.078V15.6979H159.547V22.6722H174.445V29.4981H159.547V36.8878H176.463V36.9175Z" fill="white" />

      </SvgIcon>
    </>
  )
}

export default LogoAlt
