import React, { useState } from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import {
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  Typography,
  Container,
  IconButton,
  Hidden,
  Dialog,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router'
import Logo from './graphics/Logo'
import { SERIES_TITLE } from '../utils/constants'
import trackLinkClick from '../utils/trackLinkClick'


const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  toolbar: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  menuIcon: {
    height: '100%',
    width: '100%',
  },
  logoContainer: {
    height: '51px',
    width: '75px',
  },
  logoLink: {
    display: 'flex',
  },
  dialog: {
    background: theme.palette.primary.dark,
  },
  mobileToolbar: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileMenu: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.light,
  },
  circle1: {
    width: '25vh',
    height: '25vh',
    position: 'absolute',
    opacity: 0.5,
    top: '5%',
    right: '0%',
    transform: 'translate(75%,0)',
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
  circle2: {
    width: '25vh',
    height: '25vh',
    position: 'absolute',
    opacity: 0.5,
    bottom: '10%',
    left: '0%',
    transform: 'translate(-60%,80%)',
    borderRadius: '50%',
    background: theme.palette.primary.secondary,
  },
  link: {
    position: 'relative',
    color: theme.palette.primary.grey,
    transition: 'color 0.15s ease',
    '&:hover': {
      color: theme.palette.primary.light,

    },
    '&:hover $highlight': {
      transform: 'scaleX(1)',
    },
  },
  highlight: {
    width: '100%',
    height: '6px',
    position: 'absolute',
    bottom: 0,
    background: theme.palette.secondary.tertiary,
    transform: 'scaleX(0)',
    transition: 'transform 0.15s ease',
    transformOrigin: 'left',
    zIndex: -1,
  },
}))

const Navbar = () => {
  const {
    menuIcon, logoContainer, logo, ...classes
  } = useStyles()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const location = useLocation()
  return (
    <AppBar className={classes.root} position="static">
      <Container maxWidth="xl">
        <Toolbar className={classes.toolbar} variant="dense" disableGutters>
          <TransitionLink
            exit={{ length: 0.4, delay: 0 }}
            entry={{ delay: 0.1, length: 0.4 }}
            to="/"
            className={classes.logoLink}
            id="logo-link"
            aria-labelledby="logoLabel"
            onClick={trackLinkClick}
          >
            <Logo labelId="logoLabel" />
          </TransitionLink>
          <Hidden xsDown>

            <Box ml="auto" display="flex">

              <TransitionLink
                to="/series"
                exit={{ length: 0.4, delay: 0 }}
                entry={{ delay: 0.1, length: 0.4 }}
                style={{ textDecoration: 'none', color: 'unset', position: 'relative' }}
                className={classes.link}
                id="series-link"
                onClick={trackLinkClick}
              >
                <Box className={classes.highlight} />
                <Typography variant="h6" className={classes.link}>{SERIES_TITLE}</Typography>
              </TransitionLink>
              <Box ml={{ xs: 3, md: 6.25 }} display="flex" className={classes.link}>

                <TransitionLink
                  to="/browse-all"
                  exit={{ length: 0.4, delay: 0 }}
                  entry={{ delay: 0.1, length: 0.4, state: { location: typeof window !== 'undefined' ? window.location.pathname : '' } }}
                  style={{ textDecoration: 'none', color: 'unset', position: 'relative' }}
                  id="browse-all-link"
                  onClick={trackLinkClick}
                >
                  <Box className={classes.highlight} />
                  <Typography variant="h6" className={classes.link}>Browse</Typography>
                </TransitionLink>
              </Box>
              <Box ml={{ xs: 3, md: 6.25 }} display="flex" className={classes.link}>

                <TransitionLink
                  to="/about"
                  exit={{ length: 0.4, delay: 0 }}
                  entry={{ delay: 0.1, length: 0.4 }}
                  style={{ textDecoration: 'none', color: 'unset', position: 'relative' }}
                  id="about-link"
                  onClick={trackLinkClick}
                >
                  <Box className={classes.highlight} />
                  <Typography variant="h6">About</Typography>

                </TransitionLink>
              </Box>
            </Box>
          </Hidden>
          <Hidden smUp>
            {(location.pathname !== '/browse-all/' && location.pathname !== '/browse-all') && (
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setIsMobileMenuOpen(true)}>
              <MenuIcon style={{ fontSize: '32px' }} />
            </IconButton>
            )}
          </Hidden>


        </Toolbar>
        <Dialog
          fullScreen
          open={isMobileMenuOpen}
        >
          <Container maxWidth="xl" className={classes.dialog}>
            <Toolbar className={classes.mobileToolbar} variant="dense" disableGutters>
              <Box onClick={() => setIsMobileMenuOpen(false)}>
                <TransitionLink
                  exit={{ length: 0.4, delay: 0 }}
                  entry={{ delay: 0.1, length: 0.4 }}
                  to="/"
                  className={classes.logoLink}
                  onClick={trackLinkClick}
                >
                  <Logo labelId="1234" />
                </TransitionLink>
              </Box>
              <IconButton edge="end" color="inherit" aria-label="close menu" onClick={() => setIsMobileMenuOpen(false)}>
                <CloseIcon style={{ fontSize: '32px' }} />
              </IconButton>
            </Toolbar>
          </Container>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" height="100%" position="relative" overflow="hidden" className={classes.mobileMenu}>
            <Box my={{ xs: 3 }} display="flex" onClick={() => setIsMobileMenuOpen(false)}>
              <TransitionLink
                to="/"
                exit={{ length: 0.4, delay: 0 }}
                entry={{ delay: 0.1, length: 0.4 }}
                style={{ textDecoration: 'none', color: 'unset' }}
                id="series-link"
                onClick={trackLinkClick}
              >
                <Typography variant="h4">Home</Typography>
              </TransitionLink>
            </Box>
            <Box my={{ xs: 3 }} display="flex" onClick={() => setIsMobileMenuOpen(false)}>
              <TransitionLink
                to="/series"
                exit={{ length: 0.4, delay: 0 }}
                entry={{ delay: 0.1, length: 0.4 }}
                style={{ textDecoration: 'none', color: 'unset' }}
                id="series-link"
                onClick={trackLinkClick}
              >
                <Typography variant="h4">{SERIES_TITLE}</Typography>
              </TransitionLink>
            </Box>
            <Box my={{ xs: 3 }} display="flex" onClick={() => setIsMobileMenuOpen(false)}>
              <TransitionLink
                to="/browse-all"
                exit={{ length: 0.4, delay: 0 }}
                entry={{ delay: 0.1, length: 0.4, state: { location: typeof window !== 'undefined' ? window.location.pathname : '' } }}
                style={{ textDecoration: 'none', color: 'unset' }}
                id="browse-all-link"
                onClick={trackLinkClick}
              >
                <Typography variant="h4">Browse</Typography>
              </TransitionLink>
            </Box>
            <Box my={{ xs: 3 }} display="flex" onClick={() => setIsMobileMenuOpen(false)}>
              <TransitionLink
                to="/about"
                exit={{ length: 0.4, delay: 0 }}
                entry={{ delay: 0.1, length: 0.4 }}
                style={{ textDecoration: 'none', color: 'unset' }}
                id="about-link"
                onClick={trackLinkClick}
              >
                <Typography variant="h4">About</Typography>
              </TransitionLink>
            </Box>
            <Box className={classes.circle1} />
            <Box className={classes.circle2} />
          </Box>

        </Dialog>
      </Container>
    </AppBar>
  )
}
export default Navbar
