const x = 1
const TEMPLATE_KEYS = ['Audio', 'Video', 'MixedMedia']
const TYPES = { Video: 'watch', Audio: 'listen', MixedMedia: 'read' }
const IS_SAFARI = typeof navigator !== 'undefined'
  && navigator.userAgent.indexOf('Safari') !== -1
  && navigator.userAgent.indexOf('Chrome') === -1
const IS_FIREFOX = typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Firefox') !== -1
const HAS_FILTER = typeof navigator !== 'undefined' && !IS_SAFARI && !IS_FIREFOX
const SERIES_TITLE = 'Specials & Series'
export {
  x,
  TEMPLATE_KEYS,
  TYPES,
  IS_SAFARI,
  IS_FIREFOX,
  HAS_FILTER,
  SERIES_TITLE,
}
