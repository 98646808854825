import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import SvgIcon from '@material-ui/core/SvgIcon'
import HiddenText from '../HiddenText'

const useStyles = makeStyles({
  logo: {
    height: '100%',
    width: '100%',
    maxWidth: '147px',
    maxHeight: '32px',
  },
})

const Logo = ({ labelId }) => {
  const {
    logo,
  } = useStyles()

  return (
    <>
      <HiddenText id={labelId}>Rise | Empowered by TriNet</HiddenText>
      <SvgIcon className={logo} fontSize="large" viewBox="0 0 536 97">
        <g clipPath="url(#clip02394)">
          <path d="M94.8649 12.6374L12.6364 94.8658C9.73683 97.7654 5.08652 97.7654 2.18691 94.8658C-0.712694 91.9662 -0.712694 87.3159 2.18691 84.4163L84.4153 2.18789C87.3149 -0.711718 91.9652 -0.711718 94.8649 2.18789C97.7097 5.03278 97.7097 9.7378 94.8649 12.6374Z" fill="white" />
          <path d="M29.4882 77.9611L12.6377 94.8117C9.73805 97.7113 5.08774 97.7113 2.18813 94.8117C-0.711474 91.9121 -0.711474 87.2618 2.18813 84.3622L19.0387 67.5116C21.9383 64.612 26.5886 64.612 29.4882 67.5116C32.3878 70.4112 32.3878 75.1162 29.4882 77.9611Z" fill="#307EDA" />
          <path d="M61.3298 12.8581L12.8571 61.3308C9.95753 64.2304 5.30722 64.2304 2.40761 61.3308C-0.491991 58.4312 -0.491991 53.7808 2.40761 50.8812L50.8803 2.40859C53.7799 -0.491015 58.4302 -0.491015 61.3298 2.40859C64.2294 5.3082 64.2294 9.95851 61.3298 12.8581Z" fill="white" />
          <path d="M94.7568 46.2297L46.2841 94.7023C43.3845 97.602 38.7342 97.602 35.8346 94.7023C32.935 91.8027 32.935 87.1524 35.8346 84.2528L84.2525 35.7802C87.1522 32.8806 91.8025 32.8806 94.7021 35.7802C97.6017 38.6798 97.6017 43.3848 94.7568 46.2297Z" fill="white" />
          <path d="M56.1319 14.9923C60.2109 14.9923 63.5177 11.6855 63.5177 7.60649C63.5177 3.52743 60.2109 0.220703 56.1319 0.220703C52.0528 0.220703 48.7461 3.52743 48.7461 7.60649C48.7461 11.6855 52.0528 14.9923 56.1319 14.9923Z" fill="#40A36D" />
          <path d="M89.5052 48.4176C93.5842 48.4176 96.891 45.1108 96.891 41.0318C96.891 36.9527 93.5842 33.646 89.5052 33.646C85.4261 33.646 82.1194 36.9527 82.1194 41.0318C82.1194 45.1108 85.4261 48.4176 89.5052 48.4176Z" fill="#307EDA" />
          <path d="M89.6143 14.7716C93.6934 14.7716 97.0001 11.4648 97.0001 7.38579C97.0001 3.30673 93.6934 0 89.6143 0C85.5352 0 82.2285 3.30673 82.2285 7.38579C82.2285 11.4648 85.5352 14.7716 89.6143 14.7716Z" fill="#FF5B16" />
          <path d="M24.2911 80.1485C28.3701 80.1485 31.6768 76.8418 31.6768 72.7627C31.6768 68.6837 28.3701 65.377 24.2911 65.377C20.212 65.377 16.9053 68.6837 16.9053 72.7627C16.9053 76.8418 20.212 80.1485 24.2911 80.1485Z" fill="#6630DA" />
          <path d="M152.311 58.4271H142.081V80.6392H126.817V16.3555H155.484C169.271 16.3555 178.517 24.7807 178.517 37.3639C178.517 45.4609 174.359 51.7525 167.466 55.3634L183.769 80.6392H165.879L152.311 58.4271ZM142.081 45.7892H152.421C158.603 45.7892 162.761 42.3972 162.761 37.3639C162.761 32.3307 159.15 28.9934 153.788 28.9934H142.081V45.7892Z" fill="white" />
          <path d="M191.756 16.3555H207.02V80.6392H191.756V16.3555Z" fill="white" />
          <path d="M218.892 73.1448L224.637 62.9141C230.381 67.3456 236.345 68.9322 241.925 68.9322C248.873 68.9322 252.594 67.0173 252.594 63.1877C252.594 59.0297 247.888 57.279 240.776 55.3642L237.494 54.4888C226.114 51.4251 218.017 46.7748 218.017 35.997C218.017 23.7421 227.92 15.481 242.855 15.481C250.733 15.481 258.557 17.724 265.341 22.8667L258.666 33.4804C253.797 29.8149 248.873 28.1189 243.019 28.1189C237.384 28.1189 233.719 30.1978 233.719 33.4804C233.719 37.4742 238.314 39.0608 244.332 40.5926L247.615 41.5227C257.955 44.3129 268.295 50.0574 268.295 61.6558C268.295 73.5278 258.064 81.4606 242.746 81.4606C233.609 81.5154 225.184 78.561 218.892 73.1448Z" fill="white" />
          <path d="M325.303 68.056V80.6392H278.854V16.3555H324.592V28.9387H294.118V41.7954H321.583V54.3786H294.118V68.0013H325.303V68.056Z" fill="white" />
          <path d="M351.071 17.9458H367.156V20.2436H353.588V27.8482H365.733V30.0913H353.588V37.9148H367.32V40.2126H351.071V17.9458Z" fill="white" />
          <path d="M371.97 23.7448H374.432V26.5349C375.526 24.8937 377.003 23.4165 379.738 23.4165C382.419 23.4165 384.115 24.839 385.045 26.6991C386.249 24.8937 388 23.4165 390.845 23.4165C394.619 23.4165 396.972 25.9879 396.972 30.0364V40.2123H394.455V30.5835C394.455 27.4103 392.869 25.6049 390.188 25.6049C387.726 25.6049 385.647 27.465 385.647 30.6929V40.1576H383.24V30.5287C383.24 27.465 381.599 25.6049 379.027 25.6049C376.401 25.6049 374.432 27.7933 374.432 30.8023V40.2123H371.97V23.7448Z" fill="white" />
          <path d="M402.225 23.7448H404.687V27.082C406 25.1125 407.969 23.4165 410.978 23.4165C414.917 23.4165 418.856 26.5349 418.856 31.9512V32.0059C418.856 37.3674 414.972 40.5953 410.978 40.5953C407.914 40.5953 405.945 38.954 404.687 37.0939V45.355H402.225V23.7448ZM416.34 32.0059V31.9512C416.34 28.0668 413.659 25.6049 410.54 25.6049C407.477 25.6049 404.577 28.1762 404.577 31.9512V32.0059C404.577 35.8356 407.477 38.3522 410.54 38.3522C413.714 38.3522 416.34 35.9997 416.34 32.0059Z" fill="white" />
          <path d="M422.357 32.0606V32.0059C422.357 27.3556 425.968 23.4165 430.947 23.4165C435.871 23.4165 439.536 27.3009 439.536 31.9512V32.0059C439.536 36.6562 435.871 40.5953 430.892 40.5953C425.968 40.5953 422.357 36.7109 422.357 32.0606ZM437.02 32.0606V32.0059C437.02 28.4498 434.394 25.5502 430.892 25.5502C427.336 25.5502 424.874 28.4498 424.874 31.8965V31.9512C424.874 35.5073 427.5 38.3522 430.947 38.3522C434.503 38.4069 437.02 35.5073 437.02 32.0606Z" fill="white" />
          <path d="M441.725 23.7432H444.351L448.673 36.9282L453.104 23.6338H455.129L459.56 36.9282L463.882 23.7432H466.454L460.654 40.3202H458.521L454.144 27.354L449.712 40.3202H447.524L441.725 23.7432Z" fill="white" />
          <path d="M477.012 38.4616C479.255 38.4616 480.842 37.5316 482.21 36.1638L483.741 37.5316C482.1 39.3917 480.076 40.5953 476.957 40.5953C472.417 40.5953 468.751 37.1486 468.751 32.0059C468.751 27.2462 472.088 23.4165 476.629 23.4165C481.498 23.4165 484.288 27.3009 484.288 32.1153C484.288 32.3342 484.288 32.6077 484.234 32.9907H471.158C471.596 36.4374 474.113 38.4616 477.012 38.4616ZM481.827 31.0211C481.553 28.0121 479.802 25.4955 476.574 25.4955C473.73 25.4955 471.596 27.848 471.213 31.0211H481.827Z" fill="white" />
          <path d="M488.61 23.7427H491.072V28.0648C492.276 25.3293 494.683 23.3598 497.802 23.4692V26.0952H497.637C494.027 26.0952 491.127 28.6666 491.127 33.6452V40.2103H488.665V23.7427H488.61Z" fill="white" />
          <path d="M508.033 38.4616C510.276 38.4616 511.863 37.5316 513.23 36.1638L514.762 37.5316C513.121 39.3917 511.097 40.5953 507.978 40.5953C503.437 40.5953 499.772 37.1486 499.772 32.0059C499.772 27.2462 503.109 23.4165 507.65 23.4165C512.519 23.4165 515.309 27.3009 515.309 32.1153C515.309 32.3342 515.309 32.6077 515.255 32.9907H502.179C502.617 36.4374 505.133 38.4616 508.033 38.4616ZM512.847 31.0211C512.574 28.0121 510.823 25.4955 507.595 25.4955C504.75 25.4955 502.617 27.848 502.234 31.0211H512.847Z" fill="white" />
          <path d="M518.701 32.0056V31.9509C518.701 26.5893 522.64 23.3615 526.579 23.3615C529.643 23.3615 531.612 25.0027 532.87 26.8629V16.9604H535.332V40.212H532.87V36.8747C531.557 38.8443 529.588 40.5403 526.579 40.5403C522.585 40.5403 518.701 37.4218 518.701 32.0056ZM532.925 32.0056V31.9509C532.925 28.1759 530.026 25.6046 526.962 25.6046C523.789 25.6046 521.163 27.9571 521.163 31.9509V32.0056C521.163 35.8899 523.843 38.3519 526.962 38.3519C530.026 38.3519 532.925 35.7805 532.925 32.0056Z" fill="white" />
          <path d="M353.095 75.281V78.4542H350.633V55.2026H353.095V65.2692C354.408 63.2996 356.378 61.6037 359.387 61.6037C363.326 61.6037 367.265 64.7221 367.265 70.1383V70.193C367.265 75.5546 363.381 78.7824 359.387 78.7824C356.323 78.7824 354.354 77.0865 353.095 75.281ZM364.748 70.2478V70.193C364.748 66.3087 362.068 63.8467 358.949 63.8467C355.885 63.8467 352.986 66.4181 352.986 70.193V70.2478C352.986 74.0774 355.885 76.5941 358.949 76.5941C362.122 76.5394 364.748 74.1868 364.748 70.2478Z" fill="white" />
          <path d="M370.001 82.7764L370.822 80.8616C371.642 81.2993 372.354 81.4634 373.393 81.4634C374.87 81.4634 375.8 80.6975 376.785 78.3997L369.29 61.9868H371.971L377.934 75.7189L383.241 61.9868H385.867L378.973 79.0015C377.551 82.3935 375.964 83.6518 373.502 83.6518C372.135 83.5971 371.15 83.3235 370.001 82.7764Z" fill="white" />
          <path d="M404.468 60.618H397.684V56.0771H416.121V60.618H409.337V78.3986H404.413V60.618H404.468Z" fill="white" />
          <path d="M417.488 61.3299H422.303V64.7766C423.287 62.424 424.874 60.8922 427.719 61.0016V66.0896H427.445C424.217 66.0896 422.248 68.0044 422.248 72.1076V78.3992H417.433V61.3299H417.488Z" fill="white" />
          <path d="M430.892 55.1465H435.98V59.4685H430.892V55.1465ZM431.002 61.3287H435.816V78.398H431.002V61.3287Z" fill="white" />
          <path d="M440.904 56.1309H445.445L455.894 69.863V56.1309H460.709V78.3976H456.551L445.773 64.2279V78.3976H440.959V56.1309H440.904Z" fill="white" />
          <path d="M464.703 69.9728V69.9181C464.703 65.049 468.149 61.0552 473.128 61.0552C478.818 61.0552 481.444 65.4866 481.444 70.3011C481.444 70.6841 481.389 71.1217 481.389 71.5594H469.517C470.009 73.7478 471.541 74.8967 473.675 74.8967C475.316 74.8967 476.465 74.4043 477.833 73.146L480.623 75.6079C479.037 77.5775 476.739 78.7811 473.675 78.7811C468.532 78.7811 464.703 75.1702 464.703 69.9728ZM476.739 68.5504C476.465 66.362 475.152 64.9396 473.128 64.9396C471.104 64.9396 469.791 66.362 469.462 68.5504H476.739Z" fill="white" />
          <path d="M485.875 73.5843V65.4873H483.851V61.3294H485.875V56.9526H490.689V61.3294H494.683V65.4873H490.689V72.7637C490.689 73.8579 491.182 74.405 492.276 74.405C493.151 74.405 493.972 74.1861 494.683 73.8032V77.6876C493.644 78.2894 492.495 78.6723 490.853 78.6723C487.844 78.6723 485.875 77.5234 485.875 73.5843Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip02394">
            <rect width="535.278" height="97" fill="white" />
          </clipPath>
        </defs>
      </SvgIcon>
    </>
  )
}

Logo.propTypes = {
  labelId: PropTypes.string.isRequired,
}

export default Logo
