import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import { PropTypes } from 'prop-types'
import { Box, CssBaseline } from '@material-ui/core'
import Footer from './Footer'
import Navbar from './Navbar'
import Theme from './Theme'
import useSiteMetadata from './SiteMetadata'
import './layout.css'
import Loader from './Loader'


if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  const smoothscroll = require('smoothscroll-polyfill')
  smoothscroll.polyfill()
}

const TemplateWrapper = ({
  children, includeNavbar, includeFooter, hasLoader, metaData,
}) => {
  const { title, description, siteUrl } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{metaData?.title ? `Rise | ${metaData.title}` : title}</title>
        <meta name="title" content={metaData?.metaTitle || title} />
        <meta name="description" content={metaData?.metaDescription || description} />

        <meta property="og:type" content={metaData?.type || 'business.business'} />
        <meta property="og:url" content={metaData?.slug ? `${siteUrl}${metaData.slug}` : siteUrl} />
        <meta property="og:title" content={metaData?.metaTitle || title} />
        <meta property="og:description" content={metaData?.metaDescription || description} />
        <meta property="og:image" content={metaData?.image ? `${siteUrl}${metaData.image}` : `${siteUrl}/img/og-image.jpg`} />


        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={metaData?.slug ? `${siteUrl}${metaData.slug}` : siteUrl} />
        <meta name="twitter:title" content={metaData?.metaTitle || title} />
        <meta name="twitter:description" content={metaData?.metaDescription || description} />
        <meta name="twitter:image" content={metaData?.image ? `${siteUrl}${metaData.image}` : `${siteUrl}/img/og-image.jpg`} />


        <meta name="msapplication-TileColor" content="#0c0f11" />
        <meta name="theme-color" content="#0c0f11" />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="favicon/safari-pinned-tab.svg"
          color="#0c0f11"
        />
      </Helmet>
      <Theme>
        <CssBaseline />
        {includeNavbar && <Navbar />}
        <Box component="main" minHeight="100vh">
          {children}
        </Box>
        {includeFooter && <Footer />}
        {hasLoader && <Loader />}
      </Theme>
    </div>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  includeFooter: PropTypes.bool,
  includeNavbar: PropTypes.bool,
  hasLoader: PropTypes.bool,
  metaData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    image: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
  }),
}

TemplateWrapper.defaultProps = {
  includeFooter: true,
  includeNavbar: true,
  hasLoader: false,
  metaData: {},
}

export default TemplateWrapper
