import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
  makeStyles,
  Container,
  Box,
  Typography,
  Grid,
  useMediaQuery,
} from '@material-ui/core'

import { SERIES_TITLE } from '../utils/constants'

import LogoAlt from './graphics/LogoAlt'
import trackLinkClick from '../utils/trackLinkClick'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.black,
  },
  link: {
    color: theme.palette.primary.grey,
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
    zIndex: 0,
    '&:hover': {
      color: theme.palette.primary.light,
    },
    '&:hover $linkBorder': {
      transform: 'scaleX(1)',
    },
  },
  linkBorder: {
    width: '100%',
    height: '6px',
    position: 'absolute',
    bottom: 4,
    left: 0,
    background: theme.palette.primary.main,
    transform: 'scaleX(0)',
    transition: 'transform 0.15s ease',
    transformOrigin: 'left',
    zIndex: -1,
  },
  greyLink: {
    color: theme.palette.primary.grey,
    textDecoration: 'none',
    fontSize: '14px',
    opacity: 0.7,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  markerFont: {
    fontFamily: 'permanent_markerregular',
  },
  footerLinks: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },
}))

export default function RiseFooter() {
  const data = useStaticQuery(graphql`
  query NonPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/footer.md/" }) {
      frontmatter {
        copyright
        terms
        privacy
      }
    }
  }
`)?.markdownRemark?.frontmatter

  const { copyright, terms, privacy } = data
  const classes = useStyles()
  const isMdOrBelow = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Box py={{ xs: 5, md: 10 }} component="footer" className={classes.root}>
      <Container maxWidth="xl" className={classes.innerRoot}>
        <Box mb={5}>
          <LogoAlt />
        </Box>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box mb={{ xs: 3.75, md: 12 }}>
              <Typography variant="body1">
                A collection of content that
                {' '}
                <br />
                {' '}
                celebrates your voice.
              </Typography>
              <Typography variant="body1">
                <strong className={classes.markerFont} style={{ fontSize: '22px', lineHeight: '120%' }}>
                  Now is your moment!
                </strong>
              </Typography>
            </Box>

          </Grid>
          <Grid item xs={12} md={6} className={classes.footerLinks}>
            <Box>
              <Box textAlign={isMdOrBelow ? 'left' : 'right'} width={{ xs: '50%', md: '100%' }}>
                <Box mb={2.5}>
                  <a
                    href="https://www.trinet.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                    id="footer-visit-trinet-link"
                    onClick={trackLinkClick}
                  >
                    <Typography variant="body1">Visit TriNet</Typography>
                    <span className={classes.linkBorder} />
                  </a>
                </Box>
                <Box mb={2.5}>
                  <Link
                    to="/series"
                    className={classes.link}
                    id="footer-series-link"
                    onClick={trackLinkClick}
                  >
                    <Typography variant="body1">{SERIES_TITLE}</Typography>
                    <span className={classes.linkBorder} />
                  </Link>
                </Box>
              </Box>
              <Box textAlign={isMdOrBelow ? 'left' : 'right'} width={{ xs: '50%', md: '100%' }}>
                <Box mb={2.5}>
                  <Link
                    to="/about"
                    className={classes.link}
                    id="footer-about-link"
                    onClick={trackLinkClick}
                  >
                    <Typography variant="body1">About</Typography>
                    <span className={classes.linkBorder} />
                  </Link>
                </Box>
              </Box>

            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box mb={1.5} className={classes.greyLink}>
              <Typography variant="caption">{copyright}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent={isMdOrBelow ? 'flex-start' : 'flex-start'}>
              <Box mr={3.25}>
                <Link
                  to={terms || ''}
                  className={classes.greyLink}
                  onClick={trackLinkClick}
                >
                  <Typography style={{ color: 'inherit' }} variant="caption"><strong>Terms of use</strong></Typography>
                </Link>
              </Box>
              <Box>
                <Link
                  to={privacy || ''}
                  className={classes.greyLink}
                  onClick={trackLinkClick}
                >
                  <Typography style={{ color: 'inherit' }} variant="caption"><strong>Privacy</strong></Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
